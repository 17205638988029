/**
 * @param {keyof NodeJS.ProcessEnv} key
 * @param {any} [defaultValue]
 * @returns {any}
 */
function env(key, defaultValue) {
  if (!process.env[key] && defaultValue === undefined) {
    throw new Error(`Missing environment variable: ${key}`);
  }
  if (!process.env[key]) {
    return defaultValue;
  }

  const value = process.env[key];

  switch (value.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'null':
      return null;
  }

  return value;
}


export const recaptcha = {
  enabled: env('VUE_APP_RECAPTCHA_ENABLED', true),
  siteKey: env('VUE_APP_RECAPTCHA_SITE_KEY'),
}

export const sentry = {
  tracesSampleRate: parseFloat(env('VUE_APP_SENTRY_TRACES_SAMPLE_RATE', 0.1)),
  environment: env('VUE_APP_SENTRY_ENV'),
  enabled: ['staging', 'production'].includes(env('VUE_APP_SENTRY_ENV')),
  dsn: env('VUE_APP_SENTRY_DSN', null),
}
