import axios from '@/vendor/axios';

export default {
  getTrip(tripId) {
    return axios.get(`/trip/${tripId}`).then((response) => response.data);
  },
  getActiveTrip() {
    return axios.get(`/commute/active-trip`).then((response) => response.data);
  },
  endTrip(userExpectsSubsidy, trip_id) {
    return axios
      .post(`/commute/${trip_id}/end`, {
        subsidized: userExpectsSubsidy,
      })
      .then((response) => response.data);
  },
  searchTripCode(code) {
    return axios
      .get(`/driver-trip-code/${code}`)
      .then((response) => response.data);
  },
  bookTrip(prospectId) {
    return axios
      .post('/driver-trip-prospect/book', {
        prospect_id: prospectId,
      })
      .then((response) => response.data);
  },
  createOnDemandTrip(data) {
    return axios
      .post('/on-demand-trip', data)
      .then((response) => response.data);
  },
  createTripCode(driverTripId) {
    return axios
      .post(`/driver-trip/${driverTripId}/code`)
      .then((response) => response.data);
  },
  /**
   * 
   * @param {number} driverTripId 
   * @param {UserLocation} position 
   * @returns {Promise<TripPositionResponse>}
   */
  createTripPosition(driverTripId, position) {
    return axios
      .post(`/driver-trip/${driverTripId}/position`, position)
      .then((response) => response.data);
  },
};
