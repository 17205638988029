/* eslint-disable no-shadow */
import { v4 as uuidv4 } from 'uuid';
import onboardingApi from '@/api/onboarding';
import appTypes from '@/store/modules/app-types';
import { initFormbricks } from '@/plugins/Formbricks';
import { setupEchoListeners } from "@/vendor/echoListeners"
import { EventBus } from '@/vendor/events';

// initial state
const state = () => ({
  fingerprint: null,
  menuOpen: false,
  platformMenuOpen: false,
  statusbarHeight: 0, // Currently not in use
  isOfferCommuteVisited: false,
  online: true,
  edges: null,
  worker: null,
  isUpdateAvailable: false,
  isNativeUpdateAvailable: false,
  openedNotification: '',
  routerHistory: [],
  locations: [],
  lastKnownLocationUpdatedAt: null,
  loginRedirect: null,
  showStops: false,
  landingPageSignUp: {
    id: null,
    phone: null,
    countryCode: null,
    voucherCode: null,
    referrals: {},
  },
});

// getters
const getters = {
  /**
   * Components needs to have `name` property to be included
   * @returns {string[]}
   */
  [appTypes.KEEP_ALIVE_COMPONENTS](state) {
    return state.routerHistory
      .map(route =>
        route.matched
          .map(match =>
            Object.values(match.components).map(component => component.name)
          )
          .flat()
      )
      .flat()
      .filter(name => typeof name !== 'undefined');
  },
  /**
   * @param {Object} state
   * @returns {UserLocation}
   */
  [appTypes.LAST_KNOWN_LOCATION](state) {
    const location = state.locations.at(-1);

    if (!location) {
      return null;
    }

    return location;
  }

};

// actions
const actions = {
  [appTypes.SET_STATUS_BAR_HEIGHT]({ commit }, height) {
    commit(appTypes.STATUS_BAR_HEIGHT_CHANGED, height - 6);
  },
  /**
   * 
   * @param {import('vuex').ActionContext} context
   * @param {UserLocation} location 
   */
  [appTypes.SET_CURRENT_LOCATION]({ commit, dispatch }, location) {
    commit(appTypes.GOT_LOCATION, location);

    dispatch('trip/throttledUpdateLocation', location, { root: true });

    EventBus.$emit('location', location);
  },
  [appTypes.GET_CURRENT_LOCATION]() {
    window.sendNative.getLocation();
  },
  [appTypes.DO_AFTER_LOGIN]({ state }) {
    initFormbricks();

    if (typeof state.loginRedirect === 'function') {
      setTimeout(() => state.loginRedirect(), 100);
    }

    setupEchoListeners()
  },
  [appTypes.SEND_REFERRALS]({ state, commit }) {
    const { referrals } = state.landingPageSignUp;

    if (!referrals || Object.keys(referrals).length === 0) {
      return Promise.resolve();
    }

    return onboardingApi
      .sendReferrals(referrals)
      .then(() => {
        commit(appTypes.RESET_LANDING_PAGE_SIGN_UP);
      });
  }
};

// mutations
const mutations = {
  [appTypes.CLEAR](currentState) {
    const newState = state();
    // Keep the first item in router istory (main/index)
    newState.routerHistory = [currentState.routerHistory[0]];
    Object.assign(currentState, newState);
  },
  [appTypes.CLOSE_MENU](state) {
    state.menuOpen = false;
  },
  [appTypes.STATUS_BAR_HEIGHT_CHANGED](state, height) {
    state.statusbarHeight = height;
  },
  [appTypes.SET_ONLINE](state, online) {
    state.online = online;
  },
  [appTypes.SET_UI_EDGES](state, edges) {
    state.edges = edges;
  },
  [appTypes.SET_UPDATE_AVAILABLE](state, available) {
    console.log(`[store/app.js] SET_UPDATE_AVAILABLE=${available}`);
    state.isUpdateAvailable = available;
  },
  [appTypes.SET_NATIVE_UPDATE_AVAILABLE](state, available) {
    console.log(`[store/app.js] SET_NATIVE_UPDATE_AVAILABLE=${available}`);
    state.isNativeUpdateAvailable = available;
  },
  [appTypes.NOTIFICATION_OPENED](state, notification) {
    state.openedNotification = notification;
  },
  [appTypes.HISTORY_PUSH](state, route) {
    state.routerHistory.push(route);
  },
  [appTypes.HISTORY_REPLACE](state, route) {
    state.routerHistory[state.routerHistory.length - 1] = route;
  },
  [appTypes.HISTORY_BACK](state, steps = -1) {
    state.routerHistory.splice(state.routerHistory.length + steps);
  },
  [appTypes.HISTORY_RESET](state, route) {
    state.routerHistory = [route];
  },
  /**
   * 
   * @param {*} state 
   * @param {UserLocation} location 
   */
  [appTypes.GOT_LOCATION](state, location) {
    if (state.locations.length >= 5) {
      state.locations.shift();
    }

    state.locations.push(location);

    state.lastKnownLocationUpdatedAt = new Date().toJSON();
  },
  [appTypes.SET_LOGIN_REDIRECT](state, redirect) {
    state.loginRedirect = redirect;
  },
  [appTypes.SET_SHOW_STOPS](state, showStops) {
    state.showStops = showStops;
  },
  [appTypes.SET_LANDING_PAGE_DATA](state, parameters) {
    Object.assign(state.landingPageSignUp, parameters);
  },
  [appTypes.RESET_LANDING_PAGE_SIGN_UP](currentState) {
    currentState.landingPageSignUp = state().landingPageSignUp;
  },
  [appTypes.SET_FINGERPRINT](state) {
    if (state.fingerprint) {
      return;
    }

    state.fingerprint = uuidv4();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
